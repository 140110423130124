import React from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import { ShowLabel } from "pages/blog-posts/edit";
import FullFeaturedCrudGrid from "components/datagrid";
import styled from "@emotion/styled";
type CustomTableProps = {
  rows: any[];
  isEditing: boolean;
  onChange?: (data: any) => void;
  register?: any;
  errors?: any;
};

const CustomTableCell = styled(TableCell)(({ theme, align }) => ({
  maxWidth: "75px",
  overflow: "hidden", // Hide overflow
  textOverflow: "ellipsis", // Show ellipsis (...) when text is too long to fit
  whiteSpace: "nowrap", // Keep the text on a single line
}));

export const CustomTable: React.FC<CustomTableProps> = ({
  rows,
  isEditing,
  onChange,
}) => {
  const withIdRows = rows.map((row, i) => ({ ...row, id: i + 1 }));
  return isEditing ? (
    <FullFeaturedCrudGrid dataRows={withIdRows} onChange={onChange} />
  ) : (
    <Table
      sx={{
        border: 1,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell align="right">
            <ShowLabel>Name</ShowLabel>
          </TableCell>
          <TableCell align="right">
            <ShowLabel>Unit_price</ShowLabel>
          </TableCell>
          <TableCell align="right">
            <ShowLabel>Qty</ShowLabel>
          </TableCell>
          <TableCell align="right">
            <ShowLabel>Subtotal</ShowLabel>
          </TableCell>
          <TableCell align="right">
            <ShowLabel>Brand</ShowLabel>
          </TableCell>
          <TableCell align="right">
            <ShowLabel>Category</ShowLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row: any, i: number) =>
          isEditing ? null : (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">
                <ShowLabel>{row.unit_price}</ShowLabel>
              </TableCell>
              <TableCell align="right">
                <ShowLabel>{row.qty}</ShowLabel>
              </TableCell>
              <TableCell align="right">
                <ShowLabel>{row.subtotal}</ShowLabel>
              </TableCell>
              <CustomTableCell align="right">
                <ShowLabel title={row.brand}>{row.brand}</ShowLabel>
              </CustomTableCell>
              <TableCell align="right">
                <ShowLabel>{row.category}</ShowLabel>
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};
